import { Container, Typography } from '@mui/material';
import Layout from '@common/layouts';
import Page from '@common/components/Page';

// ----------------------------------------------------------------------

HomePage.getLayout = function getLayout(page: React.ReactElement) {
  return <Layout>{page}</Layout>;
};

export default function HomePage() {
  return (
    <Page title="Alpha Academics App" height={'100%'}>
      <Container disableGutters maxWidth={false} sx={{ px: '60px' }}>
        <Typography>Alpha Academics App</Typography>
      </Container>
    </Page>
  );
}
